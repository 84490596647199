import React from "react"

import { Spacer, Container, Row, Col } from "@hurleymc/components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "src/components/link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Spacer margin="4rem .5rem">
      <Container>
        <Row>
          <Col>
            <h1>Page Not Found</h1>
            <p>We're sorry, but the page you were looking for was not found.</p>
            <p>
              <Link to={"/"}>Head back home.</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Spacer>
  </Layout>
)

export default NotFoundPage
